// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import 'channels'

Rails.start();
ActiveStorage.start();

// global jquery
import $ from 'jquery';
global.$ = jQuery;

// load images
require.context('images', true);

// main scss for components
import 'stylesheets/application'

// bootstrap
import 'bootstrap';
import { Tooltip, Popover, Modal } from "bootstrap";
window.Modal = Modal;

$(function(){
  // Tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
  // Popovers
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  });
});
// datetime
import { TempusDominus, DefaultOptions, Namespace } from '@eonasdan/tempus-dominus';
window.TempusDominus = TempusDominus;
window.tdOptions = DefaultOptions;
window.tdNamespace = Namespace;

/* YARN PLUGINS */
// webpack è un loader AMD quindi non serve eseguire la funzione require

// datatable https://datatables.net/download/
require( 'jszip' );
require( 'datatables.net-bs4' );
require( 'datatables.net-autofill-bs4' );
require( 'datatables.net-buttons-bs4' );
require( 'datatables.net-buttons/js/buttons.colVis.js' );
require( 'datatables.net-buttons/js/buttons.html5.js' );
require( 'datatables.net-buttons/js/buttons.print.js' );
require( 'datatables.net-keytable-bs4' );
require( 'datatables.net-responsive-bs4' );
require( 'datatables.net-select-bs4' );
// parsley
require('parsleyjs');
// metismenu (sidebar)
require('metismenu');
// datepicker
require('bootstrap-datepicker');
// chartjs
require('chart.js');

// un'altro modo di importare custom js (folder+index file)
// swal
import 'sweetalert'

/* INIT */
require('init');
require('init/bootstrap-datepicker');
require('init/datatables');
require('init/parsley/parsley.it');
require('init/tempus-dominus/tempus-dominus.it');