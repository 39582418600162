tdOptions.localization = {
  today: 'Oggi',
  clear: 'Cancella',
  close: 'Chiudi',
  selectMonth: 'Mese',
  previousMonth: 'Precedente',
  nextMonth: 'Successivo',
  selectYear: 'Anno',
  previousYear: 'Precedente',
  nextYear: 'Successivo',
  selectDecade: 'Decennio',
  previousDecade: 'Precedente',
  nextDecade: 'Successivo',
  previousCentury: 'Secolo precedente',
  nextCentury: 'Secolo successivo',
  pickHour: 'Ora',
  // incrementHour: 'Время увеличения',
  // decrementHour: 'Уменьшить час',
  pickMinute: 'Minuti',
  // incrementMinute: 'Минута приращения',
  // decrementMinute: 'Уменьшить минуту',
  pickSecond: 'Secondi',
  // incrementSecond: 'Увеличение секунды',
  // decrementSecond: 'Уменьшение секунды',
  // toggleMeridiem: 'Переключить период',
  selectTime: 'Seleziona orario',
  selectDate: 'Seleziona data',
  dayViewHeaderFormat: { month: 'long', year: '2-digit' },
  locale: 'it',
  startOfTheWeek: 1
}

tdOptions.setup = {
  display: {
    components: {
      useTwentyfourHour: true,
    },
    icons: {
      time: 'fas fa-clock',
      date: 'fas fa-calendar',
      up: 'fas fa-arrow-up',
      down: 'fas fa-arrow-down',
      previous: 'fas fa-chevron-left',
      next: 'fas fa-chevron-right',
      today: 'fas fa-calendar-check',
      clear: 'fas fa-trash',
      close: 'fas fa-times',
    },
    buttons: {
      clear: true,
      close: true
    }
  }
}